<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      tutoriais: null,
    };
  },
  methods: {
    getTutoriais() {
      this.tutoriais = null;

      api
        .get("tutoriais")
        .then((res) => {
          if (res.data.status == "success") {
            this.tutoriais = res.data.list;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
},
  mounted() {
    this.getTutoriais();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Tutoriais</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!tutoriais" class="card">
      <div class="card-body text-center"><b-spinner class="align-middle" variant="default" role="status"></b-spinner></div>
    </div>

    <div v-else-if="tutoriais && tutoriais.length == 0" class="card">
      <div class="card-body text-center">Nenhum produto encontrado.</div>
    </div>

    <div v-else>
      <a target="_blank" :href="'https://youtu.be/' + row.youtube" v-for="(row, index) in tutoriais" :key="index">
        <div class="card">
          <div class="card-body p-1 px-3 d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: #FF0000;">
              <path
                d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z">
              </path>
            </svg>
            <div class="flex-fill pl-2">
              <h5 class="m-0">{{ row.title }}</h5>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
              </svg>
            </div>
          </div>
        </div>
      </a>
    </div>
  </Layout>
</template>